import * as React from 'react';
import { graphql } from 'gatsby';
import Content from './../components/content';
import Layout from './../components/layout';
import Seo from './../components/seo';
import { GatsbyImage } from "gatsby-plugin-image";
import './../styles/team.scss'
import Markdown from '../components/markdown';

const TeamPage = ({data}) => {
  return <>
    <Layout>
      <Seo title={data.strapiTeamPage.title} />
      <h1>{data.strapiTeamPage.title}</h1>

      <Content data={data.strapiTeamPage.introduction} />
  
      <div class="cards-wrapper">
        {data.strapiTeamPage.person.map((item, index) => <>
          <div class="member-card">
            <GatsbyImage
              class="team-photo"
              image={item.Image.localFile.childImageSharp.gatsbyImageData}
              data-bs-toggle="modal"
              data-bs-target={`#member-card-modal-${index}`}
            />
            <p class="team-name">{item.Name}</p>
            {/* <div class="team-specialisations">
              {item.specialisations.map((sp) => 
                <p class="team-sp-pill">{sp.specialisation}</p>
              )}
            </div> */}
            <p class="team-short-bio">
              {item.Bio.substring(0,100).split(' ').slice(0,-1).join(' ')}
              ...
            </p>
            <p class="team-read-more">
              <a data-bs-toggle="modal" data-bs-target={`#member-card-modal-${index}`}>
                {'> Read full bio'}
              </a>
            </p>
          </div>
        </>)}
        
      </div>

      {data.strapiTeamPage.person.map((item, index) => <>
        <div
          class="modal fade"
          id={`member-card-modal-${index}`}
          tabindex="-1"
          aria-labelledby={`member-card-modal-${index}-label`}
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{item.Name}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body team-modal-body">
                <div class="team-modal-info">
                  <GatsbyImage class="team-photo" image={item.Image.localFile.childImageSharp.gatsbyImageData} />
                  <p class="team-name">{item.Name}</p>
                  {/* <div class="team-specialisations">
                    {item.specialisations.map((sp) => 
                      <p class="team-sp-pill">{sp.specialisation}</p>
                    )}
                  </div> */}
                </div>
                <div class="team-modal-bio">
                  <div class="content-text">
                    <Markdown>{item.Bio}</Markdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>)}

    </Layout>
  </>;
}

export default TeamPage;

export const pageQuery = graphql`
  query TeamQuery {
    strapiTeamPage {
      title
      introduction
      person {
        Bio
        Name
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 300, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
